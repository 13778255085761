import React from "react"
import Layout from "../components/layouts/Layout2"
import { Hero, Map } from "../components/slices"
import { Link, graphql } from "gatsby"
import MainImage from "../../static/images/woman.jpg"
import { Breadcrumb } from "../components/slices"
import AllServices from "../components/slices/AllServices"
import { Helmet } from "react-helmet"
import { renderMetaTags } from "../utils"

const Services = ({ data, path }) => {
  const { title, seoMetaTags } = data.allDatoCmsServiceListing.nodes[0]
  const breadcrumbData = [
    { title: "Home", url: "/" },
    { title, url: path },
  ]

  const heroProps = {
    title,
    mainImage: MainImage,
    variation: "small",
  }
  return (
    <Layout>
      {seoMetaTags.tags && <Helmet>{renderMetaTags(seoMetaTags.tags)}</Helmet>}
      <Hero {...heroProps} />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      <div className="[ section -small ] gallery">
        <div className="container -pinched">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
            tenetur aspernatur, inventore nostrum rerum, unde deleniti saepe
            magni incidunt odio vel minus laborum. Incidunt expedita sit
            excepturi nisi magnam qui.
          </p>
        </div>
        <div className="container">
          <div className="services-grid">
            <AllServices />
          </div>
        </div>
      </div>
      <Map />
    </Layout>
  )
}

export const query = graphql`
  query ServicesPAge {
    allDatoCmsServiceListing {
      nodes {
        title
        url
        seoMetaTags {
          tags
        }
      }
    }
    allDatoCmsService {
      nodes {
        title
        url
      }
    }
  }
`

export default Services

import React from "react"

type TitleMetaLinkTag = {
  tagName: string
  content: string | null
  attributes: Record<string, string> | null
}

export const renderMetaTags = (data: TitleMetaLinkTag[]): JSX.Element[] => {
  return data.map(({ tagName, attributes, content }) => {
    let key: string[] = [tagName]

    if (attributes && "property" in attributes) {
      key.push(attributes.property)
    }

    if (attributes && "name" in attributes) {
      key.push(attributes.name)
    }

    if (attributes && "rel" in attributes) {
      key.push(attributes.rel)
    }

    if (attributes && "sizes" in attributes) {
      key.push(attributes.sizes)
    }

    const Tag = tagName as "meta" | "title" | "link"

    return (
      <Tag key={key.join("-")} {...attributes}>
        {content}
      </Tag>
    )
  })
}

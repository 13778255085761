import React from "react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import "../../stylesheets/style.scss"

interface LayoutProps {
  children: JSX.Element | JSX.Element[]
}

export default ({ children }: LayoutProps) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Header className="v2" />
      <main>{children}</main>
      <Footer />
    </>
  )
}
